import { Button } from '@mui/material'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { CheckType } from '@planning/app/api'
import { useNavigationStore } from '@planning/stores'
import { TFnType, useTranslate } from '@tolgee/react'
import {
  BackButton,
  ConfirmationDialog,
  ContainerMobile,
  Header,
  StepperInfo,
  useMinimalsTheme,
} from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { InspectRailcarContainers } from './Components/InspectRailcar/InspectRailcarContainers'
import { InspectRailcarForm } from './Components/InspectRailcar/InspectRailcarForm'
import { RailTallyViewStore } from './Stores/RailTallyViewStore'

interface IProps {
  store: RailTallyViewStore
  onSubmit: (data: IInspectRailcarFormData) => Promise<void> | void
}

export interface IInspectRailcarFormData {
  sequence: number
  length?: number
  maxPayload?: number
}

export const checkedSuffixText = (operation: CheckType | undefined, t: TFnType) => {
  if (operation === CheckType.DischargePreparation) {
    return t('in', 'in')
  } else if (operation === CheckType.DepartureCheck) {
    return t('out', 'out')
  } else {
    return ''
  }
}

const NextStepButton: FC<{
  store: RailTallyViewStore
  isLoadPrep: boolean
  handleNextStep: () => void
}> = ({ handleNextStep, store, isLoadPrep }) => {
  const { t } = useTranslate()

  return (
    <Button
      data-cy='submit-inspect-container'
      variant='contained'
      onClick={handleNextStep}
      sx={{ width: '15%', minWidth: '110px' }}
    >
      {store.currentStep === 1 || isLoadPrep
        ? `${t('check', 'Check')} ${checkedSuffixText(store.selectedOperationType, t)}`
        : t('next', 'Next')}
    </Button>
  )
}

export const InspectRailcar: FC<IProps> = observer(({ onSubmit, store }) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const navigationStore = useNavigationStore()

  const [titleText, setTitleText] = useState(t('inspectRailcar', 'Inspect Railcar'))
  const [stepText, setStepText] = useState(t('1of2', '1 of 2'))

  const isLoadPrep = store.selectedOperationType === CheckType.LoadPreparation
  const allowContainerEditAfterTallyCheckInFF = useBooleanFlagValue(
    'allow-container-edit-after-tally-check-in',
    false,
  )

  const isDischargePreparationAndAlreadyCheckedIn =
    allowContainerEditAfterTallyCheckInFF && store.isDischargePreparationAndAlreadyCheckedIn

  useEffect(() => {
    if (isLoadPrep || isDischargePreparationAndAlreadyCheckedIn) {
      setStepText(t('1of1', '1 of 1'))
      if (isDischargePreparationAndAlreadyCheckedIn) store.setCurrentStep(1)
    } else {
      setStepText(store.currentStep === 0 ? t('1of2', '1 of 2') : t('2of2', '2 of 2'))
    }

    setTitleText(
      store.currentStep === 0
        ? t('inspectRailcar', 'Inspect Railcar')
        : t('inspectContainers', 'Inspect Containers'),
    )
  }, [store.currentStep, t, isLoadPrep, store, isDischargePreparationAndAlreadyCheckedIn])

  const railcarTrackPosition = store.selectedRailTrackPosition!

  const form = useForm<IInspectRailcarFormData>({
    defaultValues: {
      sequence:
        railcarTrackPosition &&
        railcarTrackPosition.data &&
        railcarTrackPosition.data.railcarSequenceNumber,
      length: railcarTrackPosition.data.railcarLength ?? undefined,
    },
  })

  const goToNextAndValidate = () => {
    const formData: IInspectRailcarFormData = {
      sequence: form.getValues('sequence'),
      length: form.getValues('length'),
      maxPayload: form.getValues('maxPayload'),
    }
    store.setInspectRailcarFormData(formData)

    form.trigger('sequence').then(isValid => {
      if (isValid) {
        store.setCurrentStep(1)
      }
    })
  }

  const submitAndReset = () =>
    form.handleSubmit(data => {
      store.setCurrentStep(0)
      onSubmit({ ...data, ...store.inspectRailcarFormData })
    })

  const handleNextStep =
    store.currentStep === 0 && !isLoadPrep ? goToNextAndValidate : submitAndReset()

  const handlePreviousStep = () => {
    if (store.upsertedOrders.length) {
      store.setToggleWarningDialog(true)
      return
    }

    if (isDischargePreparationAndAlreadyCheckedIn || store.currentStep === 0) {
      navigationStore.pop()
    }

    store.setCurrentStep(0)
  }

  return (
    <>
      <Header
        leftRenderOption={
          <BackButton onGoBack={handlePreviousStep} tooltip={t('return', 'Return')} />
        }
        title={railcarTrackPosition.data.railcarName}
        subTitle={railcarTrackPosition.railTrack?.data.name}
        rightRenderOption={
          !isDischargePreparationAndAlreadyCheckedIn && (
            <NextStepButton store={store} isLoadPrep={isLoadPrep} handleNextStep={handleNextStep} />
          )
        }
      />
      <StepperInfo title={titleText} steps={stepText} />

      <ContainerMobile sx={{ pt: theme.customSpacing.l }}>
        <FormProvider {...form}>
          {store.currentStep === 0 && (
            <InspectRailcarForm
              operationType={store.selectedOperationType}
              pins={store.pinsOnSelectedRailcar}
            />
          )}
          {store.currentStep === 1 && (
            <InspectRailcarContainers railcarOrders={store.selectedOrdersOnSelectedRailcar} />
          )}
        </FormProvider>
      </ContainerMobile>

      <ConfirmationDialog
        open={store.toggleWarningDialog}
        title={`${t('discardChanges?', 'Discard changes')}?`}
        message={t(
          'leavingNowWillDiscardYourUnsavedChanges',
          'Leaving now will discard your unsaved changes',
        )}
        primaryActionText={t('discard', 'Discard')}
        closeLabel={t('cancel', 'Cancel')}
        onClose={() => store.setToggleWarningDialog(false)}
        onConfirm={() => {
          navigationStore.pop()
          store.removeUpsertedOrders()
          store.setToggleWarningDialog(false)
        }}
      />
    </>
  )
})
