import { Box, Grid, Stack, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'
import { CraneQueueStats } from './widgets/CraneQueueStats'
import { EquipmentStats } from './widgets/EquipmentStats'
import { RailVisitWidget } from './widgets/RailVisitWidget'
import { TruckVisitWidget } from './widgets/TruckVisitWidget'
import { VesselVisitWidget } from './widgets/VesselVisitWidget'

export const Dashboard = observer(() => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  return (
    <Box sx={{ backgroundColor: theme.palette.grey[200], minHeight: '100%' }}>
      <Stack p={1} px={2}>
        <Typography variant='h2'>
          {t('terminalOperationOverview', 'Terminal Operation Overview')}
        </Typography>
        <Typography variant='h4'>
          {`${t('today', 'Today')} ${dayjs().format('DD.MM.YYYY')}`}
        </Typography>
      </Stack>

      <Grid container spacing={2} p={2}>
        <Grid item xs={12} lg={4}>
          <VesselVisitWidget currentDate={new Date()} />
        </Grid>
        <Grid item xs={12} lg={4}>
          <RailVisitWidget currentDate={new Date()} />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TruckVisitWidget />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h6'>{t('equipment', 'Equipment')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <EquipmentStats />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h6'>{t('cranes', 'Cranes')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <CraneQueueStats />
        </Grid>
      </Grid>
    </Box>
  )
})
