import { PATHS } from '@host/app/paths'
import { Card, CardContent, CardHeader, Grid, Stack, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { useOperationsStore } from '@tom-ui/operations'
import { ButtonOption, ButtonWithDropdown, ColoredLabel } from '@tom-ui/ui'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import { EquipmentStatsDto, EquipmentType } from 'modules/operations/src/app/api'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'

const EquipmentTypeStatField = observer(({ label, value }: { label: string; value: number }) => {
  return (
    <Stack flexDirection='row' justifyContent='space-between'>
      <Typography variant='body2' color={'secondary'}>
        {label}
      </Typography>
      <Typography variant='body1'>{value}</Typography>
    </Stack>
  )
})

const EquipmentTypeStat = observer(({ stats }: { stats: EquipmentStatsDto }) => {
  const { t } = useTranslate()
  const navigate = useNavigate()

  const getEquipmentOptions = useMemo(() => {
    const options: ButtonOption[] = []

    if (stats.assignedToYard) {
      options.push({
        label: t('equipmentPage', 'Equipment page'),
        onClick: () => navigate(`/${PATHS.registers.equipments}`),
      })
    }

    _(stats.visits)
      .unionBy(x => x.visitId)
      .value()
      .forEach(v =>
        options.push({
          label: v.carrierName ?? v.carrierType,
          onClick: () =>
            v.carrierType === 'Train'
              ? navigate(`/EquipmentPlanningRail/${v.visitId}`)
              : navigate(`/EquipmentPlanning/${v.visitId}/equipments`),
        }),
      )

    return options
  }, [navigate, stats.assignedToYard, stats.visits, t])

  return (
    <Grid xs={4} item>
      <Card sx={{ height: '100%' }}>
        <CardHeader
          sx={{ height: '5rem' }}
          title={
            <Typography variant='h3'>{t(stats.equipmentType, stats.equipmentType)}</Typography>
          }
        />
        <CardContent sx={{ height: 'calc(100% - 5rem)' }}>
          <Stack gap={1} height='100%'>
            {stats.equipmentType !== EquipmentType.Tt &&
              stats.equipmentType !== EquipmentType.Sts && (
                <Stack
                  flexDirection='row'
                  justifyContent='space-between'
                  flexWrap='wrap'
                  alignItems='center'
                  gap={1}
                >
                  <Typography variant='body2' color={'secondary'}>
                    {t('assignedToYard', 'Assigned to yard')}
                  </Typography>
                  {stats.yardBlockNames.length > 0 ? (
                    stats.yardBlockNames.map(blockName => (
                      <ColoredLabel key={blockName} label={blockName} color='primary' />
                    ))
                  ) : (
                    <Typography variant='body1'>0</Typography>
                  )}
                </Stack>
              )}

            <EquipmentTypeStatField
              label={t('assignedToVisit', 'Assigned to visit')}
              value={stats.assignedToVisit}
            />
            <EquipmentTypeStatField
              label={t('notAssigned', 'Not assigned')}
              value={stats.notAssigned}
            />

            {stats.onMaintenance > 0 && (
              <EquipmentTypeStatField
                label={t('onMaintenance', 'Maintenance')}
                value={stats.onMaintenance}
              />
            )}

            {getEquipmentOptions.length > 0 && (
              <Stack alignItems='center' height='100%' justifyContent='end'>
                <ButtonWithDropdown
                  label={t('manage', 'Manage')}
                  color='primary'
                  options={getEquipmentOptions}
                />
              </Stack>
            )}
          </Stack>
        </CardContent>
      </Card>
    </Grid>
  )
})

export const EquipmentStats = observer(() => {
  const { equipmentStore } = useOperationsStore()
  const [stats, setStats] = useState<EquipmentStatsDto[]>([])

  useEffect(() => {
    const fetchData = async () => {
      const data = await equipmentStore.loadEquipmentStatistics()
      setStats(data)
    }
    fetchData()
  }, [equipmentStore])

  return (
    <Stack gap={2} flexDirection={'row'}>
      {stats.map(group => {
        return <EquipmentTypeStat key={group.equipmentType} stats={group} />
      })}
    </Stack>
  )
})
