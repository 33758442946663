import { PATHS } from '@host/app/paths'
import { useTranslate } from '@tolgee/react'
import { usePlanningStore } from '@tom-ui/planning'
import { useMinimalsTheme } from '@tom-ui/ui'
import _ from 'lodash'
import { computed } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { PieItem } from '../components/PieGraph'
import { VisitCard } from '../components/VisitCard'
import {
  getContainerSummaryFromVisits,
  getDefaultVisitStatusForPieChart,
  isDateBetweenFromAndTo,
  updateVisitDashabordsByStatus,
} from '../helpers/helper'

interface Props {
  currentDate: Date
}
export const VesselVisitWidget = observer(({ currentDate }: Props) => {
  const { t } = useTranslate()
  const { visitListViewStore, vesselVisitItemStore } = usePlanningStore()
  const theme = useMinimalsTheme()

  useEffect(() => {
    visitListViewStore.loadCurrentPage()
  }, [visitListViewStore])

  const getVisits = () => {
    return _(vesselVisitItemStore.elements)
      .filter(visit => isDateBetweenFromAndTo(currentDate, visit.arrival, visit.departure))
      .value()
  }

  const graphItems = computed(() => {
    const items: PieItem[] = getDefaultVisitStatusForPieChart(theme, t)

    getVisits().forEach(visit => {
      updateVisitDashabordsByStatus(items, visit.status)
    })

    return items.filter(x => x.value > 0)
  }).get()

  const containersSummary = computed(() => {
    return getContainerSummaryFromVisits(getVisits())
  }).get()

  return (
    <VisitCard
      title={t('vessels', 'Vessels')}
      seeMorePath={PATHS.visits}
      graphItems={graphItems}
      containersIn={containersSummary.summaryIn}
      containersOut={containersSummary.summaryOut}
    />
  )
})
