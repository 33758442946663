import { Box } from '@mui/material'
import { useOpenFeatureClient } from '@openfeature/react-sdk'
import { CarrierVisitDirection, CheckType } from '@planning/app/api'
import { useNavigationStore } from '@planning/stores'
import { useTranslate } from '@tolgee/react'
import {
  ConfirmationDialog,
  Header,
  IconButton,
  MenuIcon,
  SearchIcon,
  useMinimalsTheme,
} from '@tom-ui/ui'
import { Dictionary } from 'lodash'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useMemo } from 'react'
import { CreateRailcar } from './Components/CreateRailcar/CreateRailcar'
import { HeaderAvatar } from './Components/HeaderComponents/HeaderAvatar'
import { InspectRailcarCheckSuccessAlert } from './Components/InspectRailcar/InspectRailcarCheckSuccessAlert'
import { InspectRailcarList } from './Components/InspectRailcar/InspectRailcarList'
import { InspectSearchRailcars } from './Components/InspectRailcar/InspectSearchRailcars'
import { ReportRailcarIssue } from './Components/Issues/ReportRailcarIssue'
import { RailcarSearchBox } from './Components/RailcarSearchBox'
import { InspectRailcar } from './InspectRailcar'
import { RailTallyViewStore } from './Stores/RailTallyViewStore'

interface IProps {
  store: RailTallyViewStore
  openDrawer: () => void
}

const operationTitleTranslationMaps: Dictionary<string> = {
  dischargePreparation: 'Discharge Preparation',
  loadPreparation: 'Load Preparation',
  departureCheck: 'Departure check',
  '': '',
}

export const ContainerRailOperations: FC<IProps> = observer(({ store, openDrawer }) => {
  const theme = useMinimalsTheme()
  const navigationStore = useNavigationStore()
  const { t } = useTranslate()

  const operation = store.selectedOperationType

  const featureFlagClient = useOpenFeatureClient()
  const canReportRailcarIssue = useMemo(
    () => featureFlagClient.getBooleanValue('missing-railcar-issue', false),
    [featureFlagClient],
  )
  const canDeleteRailcar = useMemo(
    () => !canReportRailcarIssue && operation !== CheckType.DepartureCheck,
    [canReportRailcarIssue, operation],
  )

  const railTrack = {
    name: store.selectedTrack?.data.name || 'Unknown',
    visit: store.selectedVisit?.data.name || 'Unknown',
    railcars: store.selectedRailcarsOnSelectedTrack || [],
  }

  const headerTitle = `${railTrack?.visit} · ${railTrack?.name}`
  const headerSubtitle = t(`${operation}`, operationTitleTranslationMaps[operation ?? ''])

  const deleteRailCarText =
    operation === CheckType.LoadPreparation
      ? t(
          'railCarWillBeDeletedAndMarkedForFollowUp',
          `Railcar ${store.positionToBeDeleted?.name} will be deleted from your list and marked for follow-up in the control room!`,
        )
      : t(
          'railCarWillBeDeletedAndYouCannotUndoThisAction',
          `Railcar ${store.positionToBeDeleted?.name} with containers ${store.positionToBeDeleted?.containers.join(', ')} will be deleted and you cannot undo this action!`,
        )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [store])

  const onSelectRailcar = (visitId?: number, railTrackId?: string, railcarId?: number) => {
    store.resetRequests()
    let direction: CarrierVisitDirection | undefined = undefined
    if (operation === CheckType.LoadPreparation || operation === CheckType.DepartureCheck)
      direction = CarrierVisitDirection.Outbound
    else if (operation === CheckType.DischargePreparation) direction = CarrierVisitDirection.Inbound

    store.selectRailTrackPositionById(visitId, railTrackId, railcarId, direction)

    navigationStore.push(
      <InspectRailcar
        store={store}
        onSubmit={data => {
          if (!store.selectedRailTrackPosition || !store.selectedVisit) return

          store.checkinRailcar(
            store.selectedRailTrackPosition.id,
            data.sequence,
            store.selectedRailTrackPosition.data.railcarId,
            store.selectedRailTrackPosition.data.railTrackId,
            store.selectedVisit.id,
            data.length,
            data.maxPayload,
          )

          navigationStore.clear()
          navigationStore.push(<ContainerRailOperations store={store} openDrawer={openDrawer} />)
        }}
      />,
    )
  }

  const onNewRailcar = () => {
    navigationStore.push(
      <CreateRailcar
        store={store}
        onSubmit={data => {
          if (store.selectedVisit?.id && store.selectedTrack?.id) {
            store.createRailcarAndOrders(
              store.selectedVisit.id,
              data.railcarNumber,
              store.selectedTrack.id,
              data.sequence,
              store.direction,
              data.length,
              data.maxPayload,
            )
            navigationStore.pop()
            navigationStore.pop()
            store.setCurrentStep(0)
          }
        }}
      />,
    )
  }

  const onCancel = () => {
    if (store.selectedRailTrackPosition) {
      store.removeConfirmedRailcarId(store.selectedRailTrackPosition.data.railcarId)
    }
  }

  const onSearchRailcars = () => {
    store.setSearchFilter('')

    navigationStore.push(
      <InspectSearchRailcars
        store={store}
        onSelectRailcar={railcarId =>
          onSelectRailcar(store.selectedVisit?.id, store.selectedTrack?.id, railcarId)
        }
        onNewRailcar={onNewRailcar}
        onDeleteRailcar={railcar => store.setRailToBeDeleted(railcar)}
        onReportRailcarIssue={railcar => store.setRailToBeDeleted(railcar)}
      />,
    )
  }

  return (
    <>
      <Header
        title={headerTitle}
        subTitle={headerSubtitle}
        leftRenderOption={
          <IconButton onClick={openDrawer}>
            <MenuIcon />
          </IconButton>
        }
        rightRenderOption={
          <>
            <IconButton onClick={onSearchRailcars}>
              <SearchIcon />
            </IconButton>
            <HeaderAvatar />
          </>
        }
      />

      <Box
        sx={{
          backgroundColor: theme.palette.grey[200],
          minHeight: 'calc(100vh - 65px)',
          padding: '1rem',
          display: 'flex',
          flexDirection: 'column',
          placeContent: 'start',
        }}
      >
        <RailcarSearchBox store={store} />

        <InspectRailcarList
          store={store}
          railcars={railTrack.railcars}
          operation={operation}
          onSelectRailcar={railcarId =>
            onSelectRailcar(store.selectedVisit?.id, store.selectedTrack?.id, railcarId)
          }
          onDeleteRailcar={
            canDeleteRailcar ? railcar => store.setRailToBeDeleted(railcar) : undefined
          }
          onReportRailcarIssue={
            canReportRailcarIssue
              ? railcar => navigationStore.push(<ReportRailcarIssue railcar={railcar} />)
              : undefined
          }
        />

        <Box
          sx={{
            position: 'fixed',
            bottom: '0',
            left: '0',
            width: '100%',
            padding: theme.spacing(2),
          }}
        >
          <InspectRailcarCheckSuccessAlert store={store} onCancel={onCancel} />
        </Box>
      </Box>

      <ConfirmationDialog
        open={!!store.positionToBeDeleted}
        title={`${t('deleteRailCar', 'Delete railcar')}?`}
        message={deleteRailCarText}
        primaryActionText={t('delete', 'Delete')}
        closeLabel={t('cancel', 'Cancel')}
        onClose={() => store.setRailToBeDeleted()}
        onConfirm={store.deleteRailcar}
      />
    </>
  )
})
